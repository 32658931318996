import React from 'react';
import { FaAmazon, FaFacebook, FaInstagram } from "react-icons/fa";

import './header-banner.scss';

function HeaderBanner() {
    return (
        <>
            <section className='header-banner-wrapper'>
                <div className='social-container'>
                    <a href='https://www.facebook.com/Bodybydarbyhanson' target='_blank'>
                        <FaFacebook className='icon' />
                    </a>
                    <a href="https://www.amazon.com/stores/Darby-Hanson/author/B0DLYCST68" target='_blank'>
                        <FaAmazon className='icon' />
                    </a>
                    <a href='https://www.instagram.com/bodybydarby/' target='_blank'>
                        <FaInstagram className='icon' />
                    </a>
                </div>
            </section>
        </>
    );
}

export default HeaderBanner;
import React from 'react';

import './header.scss';
import HeaderBanner from '../header-banner/header-banner';

import logoImg from '../../assets/images/logos/logo-main.png';

function Header() {
    return (
        <>
            <section className='header-wrapper'>
                <HeaderBanner />
                <div className='header-content'>
                    <div className='menu-left'>
                    </div>
                    <div className='header-logo'>
                        <img src={logoImg} alt='logo' />
                    </div>
                    <div className='menu-right'>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Header;
import React from 'react';

import './footer.scss';

function Footer() {
    return (
        <>
            <section className='footer-wrapper'>
                <div className="copyright">
                    <label>Copyright © {new Date().getFullYear()} Darby Hanson</label>
                </div>
            </section>
        </>
    );
}

export default Footer;
import React from 'react';
import Header from '../header/header'

import './home.scss';
import Footer from '../footer/footer';

import underDevImg from '../../assets/content/under-dev.png';

function Home() {

    return (
        <>
            <section className='home-wrapper'>
                <div className='header-container'>
                    <Header />
                </div>
                <div className='home-content'>
                    <img className='under-dev-img' src={underDevImg} alt='website under development' />
                </div>
                <div className='footer-container'>
                    <Footer />
                </div>
            </section>
        </>
    );
}

export default Home;
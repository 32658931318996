import './App.css';
import Home from './components/home/home';

function App() {
    return (
        <section className="App">
            <Home />
        </section>
    );
}

export default App;
